import React from 'react'
import './about.css'
import ME from '../../assets/me.png'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      {/* <h5>Get To Know</h5> */}
      <h2>一些自我介紹</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>接觸程式</h5>
              <small>4 Years </small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>FIVEM Clients</h5>
              <small>60+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>中小型專案參與</h5>
              <small>15+</small>
            </article>
          </div>

          <p>
            喜歡開發的大學生<br></br>

            我喜歡閱讀技術文章並參加技術者的社群、觀察業界職位的需求 ; <br></br>
            弱點講不完 但我還是很愛寫程式 差滴，<br></br> <br></br>

            高中開始寫fivem lua script當兼差 也是我寫程式的起源 多虧內建CEF我也學會了WEB 3件套 雖然切版還是要切個好幾天 但是 切一次換一種syntax, 從flutter web,jquery,vue到此頁的react 切版變得挺有趣 像有一次無聊就把overwatch 的UI 刻出來放進FIVEM 玩 
            不知不覺學到了不少 <br></br> <br></br>
           
            最近專注於學習 Flutter, Dart 等web/web application 一方面惡補一下algo跟資結 一方面繼續想辦法把前後端jr的東西都碰看看 接觸些許生成式AI 
            <br></br> <br></br>
            目前努力把東西做到可以投Conference的程度
            ，刷GPA+讀托福 計畫兩年後嘗試申請US MASTER 有志同道合的技術/留學朋友歡迎聯繫 謝謝 
          </p>

          <a href="#contact" className='btn btn-primary'>聯絡我</a>
        </div>
      </div>
    </section>
  )
}

export default About