// import React from 'react'
// import Header from './components/header/Header'
// import Nav from './components/nav/Nav'
// import About from './components/about/About'
// import Experience from './components/experience/Experience'
// import Services from './components/services/Services'
// import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/testimonials/Testimonials'
// import Contact from './components/contact/Contact'
// import Footer from './components/footer/Footer'












// const App = () => {
//   return (
//     <>
//         <Header />
//         <Nav />
//         <About />
//         <Experience />
//         <Services />
//         <Portfolio />
//         <Testimonials />
//         <Contact />
//         <Footer />
//     </>
//   )
// }

// export default App

// import React, { useState, useEffect } from 'react'
// import Header from './components/header/Header'
// import Nav from './components/nav/Nav'
// import About from './components/about/About'
// import Experience from './components/experience/Experience'
// import Services from './components/services/Services'
// import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/testimonials/Testimonials'
// import Contact from './components/contact/Contact'
// import Footer from './components/footer/Footer'

// const idList = ['about', 'experience', 'services', 'portfolio', 'testimonials', 'contact']

// const App = () => {
//   const [currentClass, setCurrentClass] = useState('')
//   const [inputValue, setInputValue] = useState('')
//   const [navValue, setNavValue] = useState('')
//   const [isNavigating, setIsNavigating] = useState(false)
//   const [canNavigate, setCanNavigate] = useState(true)

//   useEffect(() => {
//     const handleKeyDown = (e) => {
//       if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
//         e.preventDefault()
//         if (canNavigate) {
//           setIsNavigating(true)
//           setCanNavigate(false)
//           setTimeout(() => {
//             setCanNavigate(true)
//           }, 500)
//           const currentIndex = idList.indexOf(currentClass)
//           const nextIndex = e.key === 'ArrowDown' ? (currentIndex + 1) % idList.length : (currentIndex - 1 + idList.length) % idList.length
//           setCurrentClass(idList[nextIndex])
//           setNavValue(`cd ${idList[nextIndex]}`)
//         }
//       } else if (e.key === 'Enter') {
//         setIsNavigating(false)
//         handleCommand(navValue)
//       }
//     }
//     window.addEventListener('keydown', handleKeyDown)
//     return () => window.removeEventListener('keydown', handleKeyDown)
//   }, [currentClass, navValue, canNavigate])

//   useEffect(() => {
//     setIsNavigating(false)
//   }, [])

//   const handleCommand = (input) => {
//     if (input.startsWith('cd #')) {
//       const id = input.substring(4)
//       setCurrentClass(id)
//       setInputValue(input)
//       document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
//     } else if (input.startsWith('cd ')) {
//       const id = input.substring(3)
//       setCurrentClass(id)
//       setInputValue(input)
//       document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
//     }
//   }

//   const renderContent = () => {
//     return (
//       <>
//         <Header />
//         <Nav />
//         <div id="about">
//           <About />
//         </div>
//         <div id="experience">
//           <Experience />
//         </div>
//         <div id="services">
//           <Services />
//         </div>
//         <div id="portfolio">
//           <Portfolio />
//         </div>
//         <div id="testimonials">
//           <Testimonials />
//         </div>
//         <div id="contact">
//           <Contact />
//         </div>
//         <Footer />
//       </>
//     )
//   }

//   return (
//     <div className="App">
//       <input
//         type="text"
//         value={isNavigating ? navValue : inputValue}
//         placeholder="Enter command (e.g. cd about)"
//         onKeyDown={(e) => {
//           if (e.key === 'Backspace' && navValue.length === 3) {
//             e.preventDefault()
//           }
//         }}
//         onKeyUp={(e) => {
//           if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
//             e.preventDefault()
//             if (canNavigate) {
//               setIsNavigating(true)
//             setCanNavigate(false)
//             setTimeout(() => {
//               setCanNavigate(true)
//             }, 500)
//           }
//             const currentIndex = idList.indexOf(currentClass)
//             const nextIndex = e.key === 'ArrowDown' ? (currentIndex + 1) % idList.length : (currentIndex - 1 + idList.length) % idList.length
//             setCurrentClass(idList[nextIndex])
//             setNavValue(`cd ${idList[nextIndex]}`)
//           } else if (e.key === 'Enter') {
//             setIsNavigating(false)
//             handleCommand(e.target.value)
//           }
//         }}
//         onChange={(e) => {
//           if (isNavigating) {
//             setNavValue(e.target.value)
//           } else {
//             setInputValue(e.target.value)
//           }
//         }}
//       />
//       {renderContent()}
//     </div>
//   )
// }

// export default App


import React, { useState, useEffect ,useRef } from 'react'
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import "./assets/LeagueSpartan-Bold.otf";

const idList = ['about', 'experience', 'services', 'portfolio', 'testimonials', 'contact']

const App = () => {
  const [currentClass, setCurrentClass] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [navValue, setNavValue] = useState('')
  const [isNavigating, setIsNavigating] = useState(false)
  const [canNavigate, setCanNavigate] = useState(true)

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault()
        if (canNavigate) {
          setIsNavigating(true)
          setCanNavigate(false)
          setTimeout(() => {
            setCanNavigate(true)
          }, 500)
          const currentIndex = idList.indexOf(currentClass)
          const nextIndex = e.key === 'ArrowDown' ? (currentIndex + 1) % idList.length : (currentIndex - 1 + idList.length) % idList.length
          setCurrentClass(idList[nextIndex])
          setNavValue(`cd ${idList[nextIndex]}`)
        }
      } else if (e.key === 'Enter') {
        // setIsNavigating(false)
        handleCommand(navValue)
        
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [currentClass, navValue, canNavigate])

  useEffect(() => {
    setIsNavigating(false)
    const currentHash = window.location.hash
    if (currentHash) {
      const id = currentHash.substring(1)
      setCurrentClass(id)
    }
    // add this line to reset the URL hash
    window.location.hash = ''
  }, [])
  const handleCommand = (input) => {
    if (input.startsWith('cd #')) {
      const id = input.substring(4)
      setCurrentClass(id)
      setInputValue(input)
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    } else if (input.startsWith('cd ')) {
      const id = input.substring(3)
      setCurrentClass(id)
      setInputValue(input)
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }
  }
  



  




  const renderContent = () => {
    return (
      <>
        <Header />
        <Nav />
        <div id="about">
          <About />
        </div>
        <div id="experience">
          <Experience />
        </div>
        <div id="services">
          <Services />
        </div>
        <div id="portfolio">
          <Portfolio />
        </div>
        <div id="testimonials">
          <Testimonials />
        </div>
        <div id="contact">
          <Contact />
        </div>
        <Footer />
      </>
    )
  }







  
  
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const media_height = windowSize.current[1]/18
  return (
    <div className="App">
      <Terminal
        name="Terminal ; cd <section-ID>/keydown/keyup"
        colorMode={ColorMode.Dark}
        prompt="$"
        height= {media_height}
        onInput={(input) => {
          // setIsNavigating(false)
          setInputValue(input)
          handleCommand(input)
        }}
        startingInputValue={inputValue}
      
      >
        {isNavigating ? navValue : inputValue}
      </Terminal>
      {renderContent()}
    </div>
  )
}

export default App


