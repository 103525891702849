import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {SiDiscord} from 'react-icons/si'
import {AiFillGithub} from 'react-icons/ai'
// import { useRef } from 'react';
// import emailjs from 'emailjs-com'

const Contact = () => {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_3fjfb17', 'template_ky1ucaz', form.current, 'user_641J0AWGxx4qcKi835yDq')

  //   e.target.reset()
  // };

  return (
    <section id='contact'>
      <h5>尋找我</h5>
      <h2>跟我聯絡</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>tsaidarius@gmail.com</h5>
            <a href="mailto:tsaidarius@gmail.com" target="_blank">傳送電子郵件</a>
          </article>
          <article className="contact__option">
            <SiDiscord className='contact__option-icon'/>
            <h4>Discord</h4>
            <h5>XNORGATE#3514</h5>
            {/* <a href="https://m.me/ernest.achiever" target="_blank">Send a message</a> */}
          </article>
          <article className="contact__option">
            <AiFillGithub className='contact__option-icon'/>
            <h4>Github</h4>
            <h5>XNORGATE</h5>
            <a href="https://github.com/XNORGATE" target="_blank">GITHUB</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form> */}
      </div>
    </section>
  )
}

export default Contact