import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'
import React, { useState, useEffect } from 'react'

const Header = () => {
  const [viewerCount, setViewerCount] = useState(0)

  useEffect(() => {
    const updateViewerCount = async () => {
      const response = await fetch('https://api.countapi.xyz/hit/xnor-development.com/')
      const data = await response.json()
      setViewerCount(data.value)
    }
    updateViewerCount()
  }, [])

  return (
    <header>
      <div className="container header__container">
        {/* <h5>XNORGATE</h5> */}
        <h1>XNORGATE</h1>
        <h3 className="text-light">Junior Fullstack Developer</h3>
        <h3> &nbsp;</h3>
        {/* <h3>訪問人數 : {viewerCount}</h3> */}

        <CTA />
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="me" id="avatar-me"/>
        </div>

        <a href="#contact" className='scroll__down'>👉</a>
      </div>
    </header>
  )
}

export default Header
