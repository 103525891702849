import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FiDribbble} from 'react-icons/fi'
import {FiInstagram} from 'react-icons/fi'
import {SiDiscord} from 'react-icons/si'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://instagram.com/_thr8t_" target="_blank"><FiInstagram/></a>
        <a href="https://github.com" target="_blank"><FaGithub/></a>
        <a href="https://discord.com" target="_blank"><SiDiscord/></a>
    </div>
  )
}

export default HeaderSocials