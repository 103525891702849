import React from 'react'
import './footer.css'
import {AiFillGithub} from 'react-icons/ai'
import {FiInstagram} from 'react-icons/fi'
import {SiDiscord} from 'react-icons/si'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>XNORGATE</a>

      <ul className='permalinks'>
        <li><a href="#">回頂端</a></li>
        <li><a href="#about">自介</a></li>
        <li><a href="#experience">開發經歷</a></li>
        <li><a href="#services">服務</a></li>
        <li><a href="#portfolio">歷史專案</a></li>
        <li><a href="#testimonials">好歌分享</a></li>
        <li><a href="#contact">聯繫方式</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://github.com/XNORGATE"><AiFillGithub/></a>
        <a href="https://instagram.com/_thr8t_"><FiInstagram/></a>
        <a href="https://discord.com/"><SiDiscord/></a>
      </div>

      {/* <div className="footer__copyright">
        <small>&copy; EGATOR Tutorials. All rights reserved.</small>
      </div> */}
    </footer>
  )
}

export default Footer